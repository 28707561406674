import React from 'react'
import styled from "styled-components"
import useOnOffByClick from "../utils/useOnOffByClick"
import {theme} from "../../utils/theme"

const Wrapper = styled.div`
  width: 100%;
  margin: 0 0 1em 0;
  .header {
    width: 100%;
    background-color: ${theme.main};
    color: #fff;
    padding: 0.5em 1em;
    font-weight:bold;
    font-size: 0.8rem;
    cursor:pointer;
  }
  .body{
    position:relative;
    overflow: hidden;
    &.open{
      height: auto;
      //padding: 0.5em 1em;
    }
    &.close{
      height: 0;
      //padding: 0 1em;
    }
  }
`

const Dropdown = ({title,children}) => {
  const [isOpen, toggleOpen] = useOnOffByClick(false)
  const bodyClassname = isOpen ? 'body open' : 'body close'
  return (
    <Wrapper>
      <div className="header" onClick={toggleOpen}>
        {title}
      </div>
      <div className={bodyClassname}>
        {children}
      </div>
    </Wrapper>
  )
}

export default Dropdown;