import React from "react"
import Dropdown from "./Dropdown"
import ScoringPointsTable from "./ScoringPointsTable"

const DropdownGroup = ({data}) => {
  const dataToRender = Object.entries(data)
  return (
    <>
      {dataToRender.map(([key, value]) => (
        <Dropdown key={key} title={key}>
          <ScoringPointsTable data={value} />
        </Dropdown>
      ))}
    </>
  )
}

export default DropdownGroup
