import React from "react"
import { Routing, getId, getTitle } from "../utils/routing"
import Layout from "../components/layout"
import Group from "../components/Group"
import QAItem from "../components/qaItem"
import SEO from "../components/seo"
import DropdownGroup from "../components/DropdownGroup"
import {LEAGUES, LEAGUES_PROPS} from "../utils/staticContent"

const ScoringStandardText = () => (
  <>
    <p>Cancelled, Postponed, and Rescheduled Games</p>
    <p>In the event that a game is cancelled, postponed, or rescheduled to a time outside of the original Scoring Period, the game will be suspended and disabled from the In-Play platform.</p>
    <p>Any games that are suspended and disabled will be indicated as such on our platform. Emails and other notifications may also be used to notify users of disabled and suspended games.</p>
    <p>If the event resulted in cancelled or rescheduled status and this is known before the contest start time then trades and orders for that event will be cancelled and refunded.</p>
    <p>Games are "known" to be cancelled or postponed once their status is updated as such by In-Play’s stats-provider, SportsDataIO.</p>
    <p>Suspended or Shortened Games</p>
    <p>In-Play uses reliable statistics and only includes statistics from games sports leagues deem to be official. If the sports leagues declare an event "suspended" then the statistics generated before the game is suspended will count in platform containing said event. Any statistics generated on a later date when the game resumes will not be included.</p>
  </>
)

const PropsStandardText = () => (
  <>
    <p>Winning props will be determined by the accumulated total of the actual statistics during the game. You will need to determine if the total exceeds or does not exceed the expected total of that statistic when the game goes final. Statistics can be Rebounds, Points, Assists and any other counting stat during game play.</p>
  </>
)

const RC = Routing.RULES.sub
const title = Routing.RULES.title

const RulesPage = ({location}) => (
  <Layout location={location}>
    <SEO title={title}/>
    <h1>{title}</h1>
    <p>Winning team will be determined by the total accumulated fantasy points scored when the game goes final (scoring rules summarized below).</p>
    <p>Winning total will be determined by the total accumulated fantasy points scored when the game goes final.</p>
    <p>Money line games will be determined by the total accumulated fantasy points scored when the game goes final.</p>
    <p>Participation in each contest must be made only as specified in the Terms of Use. Failure to comply with these Terms of Use will result in disqualification and, if applicable, prize forfeiture.</p>
    <Group id={getId(RC.NFL)}>
      <h2>{getTitle(RC.NFL)}</h2>
      <QAItem>
        <p className="colored-subheader">Scoring</p>
        <DropdownGroup data={LEAGUES.NFL}/>
        <ScoringStandardText/>
      </QAItem>
      <QAItem>
        <p className="colored-subheader">Props</p>
        <PropsStandardText/>
        <DropdownGroup data={LEAGUES_PROPS.NFL}/>
      </QAItem>
    </Group>

    <Group id={getId(RC.NBA)}>
      <h2>{getTitle(RC.NBA)}</h2>
      <QAItem>
        <p className="colored-subheader">Scoring</p>
        <DropdownGroup data={LEAGUES.NBA}/>
        <ScoringStandardText/>
      </QAItem>
      <QAItem>
        <p className="colored-subheader">Props</p>
        <PropsStandardText/>
        <DropdownGroup data={LEAGUES_PROPS.NBA}/>
      </QAItem>
    </Group>

    <Group id={getId(RC.NHL)}>
      <h2>{getTitle(RC.NHL)}</h2>
      <QAItem>
        <p className="colored-subheader">Scoring</p>
        <DropdownGroup data={LEAGUES.NHL}/>
        <ScoringStandardText/>
      </QAItem>
      <QAItem>
        <p className="colored-subheader">Props</p>
        <PropsStandardText/>
        <DropdownGroup data={LEAGUES_PROPS.NHL}/>
      </QAItem>
    </Group>

    <Group id={getId(RC.MLB)}>
      <h2>{getTitle(RC.MLB)}</h2>
      <QAItem>
        <p className="colored-subheader">Scoring</p>
        <DropdownGroup data={LEAGUES.MLB}/>
        <ScoringStandardText/>
      </QAItem>
      <QAItem>
        <p className="colored-subheader">Props</p>
        <PropsStandardText/>
        <DropdownGroup data={LEAGUES_PROPS.MLB}/>
      </QAItem>
    </Group>
  </Layout>
)

export default RulesPage;