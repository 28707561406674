export const tabs = ['NFL', 'NBA', 'NHL', 'MLB'];

export const LEAGUES = {
  NFL: {
    Offense: [
      {
        action: 'Passing TD',
        result: ' +4 Pts',
      },

      {
        action: '25 Passing Yards',
        result: '+1 Pt (+0.04 Pts/ Yards)',
      },

      {
        action: '300+ Yard Passing Game',
        result: '+3 Pts',
      },

      {
        action: 'Interception',
        result: '-1 Pt',
      },

      {
        action: '10 Rushing Yards',
        result: '+1 Pt (+0.1 Pts/Yard)',
      },

      {
        action: '100+ Yard Rushing Game',
        result: '\t+3 Pts',
      },

      {
        action: 'Receiving TD',
        result: '+6 Pts',
      },

      {
        action: '10 Receiving Yards',
        result: '+1 Pt (+0.1 Pts/Yard)',
      },

      {
        action: '100+ Receiving Yard Game',
        result: '+3 Pts',
      },

      {
        action: 'Reception',
        result: '\t+1 Pt',
      },

      {
        action: 'Punt/Kickoff/FG Return for TD',
        result: '+6 Pts',
      },

      {
        action: 'Fumble Lost',
        result: '\t-1 Pt',
      },

      {
        action: '2 Pt Conversion (Pass, Run, or Catch)',
        result: '+2 Pts',
      },
      {
        action: 'Offensive Fumble Recovery TD',
        result: '+6 Pts',
      },
    ],

    Defense: [
      {
        action: 'Sack',
        result: '+1 Pt',
      },

      {
        action: 'Interception',
        result: '\t+2 Pts',
      },
      {
        action: 'Fumble Recovery',
        result: '\t+2 Pts',
      },
      {
        action: 'Punt/Kickoff/FG Return for TD',
        result: '\t+6 Pts',
      },
      {
        action: 'Interception Return TD',
        result: '\t+6 Pts',
      },
      {
        action: 'Fumble Recovery TD',
        result: '+6 Pts',
      },
      {
        action: 'Blocked Punt or FG Return TD',
        result: '\t+6 Pts',
      },
      {
        action: 'Safety',
        result: '+2 Pts',
      },
      {
        action: 'Blocked Kick',
        result: '\t+2 Pts',
      },
      {
        action: '2 Pt Conversion/Extra Point Return',
        result: '\t+2 Pts',
      },
      {
        action: '0 Points Allowed',
        result: '+10 Pts',
      },
      {
        action: '1 – 6 Points Allowed',
        result: '+7 Pts',
      },
      {
        action: '7 – 13 Points Allowed',
        result: '\t+4 Pts',
      },

      {
        action: '14 – 20 Points Allowed',
        result: '\t+1 Pt',
      },
      {
        action: '21 – 27 Points Allowed',
        result: '\t+0 Pts',
      },
      {
        action: '28 – 34 Points Allowed',
        result: '-1 Pt',
      },
      {
        action: '35+ Points Allowed',
        result: '-4 Pts',
      },
    ],
  },

  NBA: {
    Points: [
      {
        action: 'Point',
        result: '+1 Pt',
      },
      {
        action: 'Made 3pt Shot',
        result: '\t+0.5 Pts',
      },
      {
        action: 'Rebound',
        result: '\t+1.25 Pts',
      },
      {
        action: 'Assist',
        result: '+1.5 Pts',
      },
      {
        action: 'Steal',
        result: '\t+2 Pts',
      },
      {
        action: 'Block',
        result: '+2 Pts',
      },
      {
        action: 'Turnover',
        result: '-0.5 Pt',
      },
      {
        action:
          'Double-Double\n' +
          '{Max 1 Per Player: Points, Rebounds, Assists, Blocks, Steals}',
        result: '+1.5 Pts',
      },

      {
        action:
          'Triple-Double\n' +
          '{Max 1 Per Player: Points, Rebounds, Assists, Blocks, Steals}',
        result: '\t+3 Pts',
      },
    ],
  },

  NHL: {
    Players: [
      {
        action: 'Goal',
        result: '+3 Pts',
      },

      {
        action: 'Assist',
        result: '\t+2 Pts',
      },

      {
        action: 'Shot on Goal',
        result: '\t+0.5 Pt',
      },

      {
        action: 'Blocked Shot',
        result: '+0.5 Pt',
      },

      {
        action: 'Short Handed Point Bonus (Goal/Assist)',
        result: '+1 Pt',
      },

      {
        action: 'Shootout Goal',
        result: '\t+0.2 Pt',
      },

      {
        action: 'Hat Trick Bonus',
        result: '+1.5 Pts',
      },
    ],
    Goalies: [
      {
        action: 'Win',
        result: '+3 Pts',
      },

      {
        action: 'Save',
        result: '+0.2 Pt',
      },
      {
        action: 'Goal Against',
        result: '\t-1 Pt',
      },
      {
        action: 'Shutout Bonus',
        result: '\t+2 Pts',
      },
    ],
  },

  MLB: {
    Hitters: [
      {
        action: 'Single',
        result: '+3 Pts',
      },

      {
        action: 'Double',
        result: '+5 Pts',
      },

      {
        action: 'Triple',
        result: '+8 Pts',
      },

      {
        action: 'Home Run',
        result: '+10 Pts',
      },

      {
        action: 'Run Batted In',
        result: '+2 Pts',
      },

      {
        action: 'Run',
        result: '+2 Pts',
      },

      {
        action: 'Base on Balls',
        result: '+2 Pts',
      },

      {
        action: 'Hit By Pitch',
        result: '+2 Pts',
      },

      {
        action: 'Stolen Base',
        result: '+5 Pts',
      },
    ],
    Pitchers: [
      {
        action: 'Inning Pitched',
        result: '+2.25 Pts',
      },

      {
        action: 'Strikeout',
        result: '+2 Pts',
      },

      {
        action: 'Win',
        result: '+4 Pts',
      },

      {
        action: 'Earned Run Allowed',
        result: '+2 Pts',
      },

      {
        action: 'Hit Against',
        result: '-0.6 Pt',
      },

      {
        action: 'Base on Balls Against',
        result: '-0.6 Pt',
      },
      {
        action: 'Hit Batsman',
        result: '-0.6 Pt',
      },
      {
        action: 'Complete Game',
        result: '+2.5 Pts',
      },
      {
        action: 'Complete Game Shutout',
        result: '\t+2.5 Pts',
      },
      {
        action: 'No Hitter',
        result: '+5 Pts',
      },
    ],
  },
};

export const LEAGUES_PROPS = {
  NFL: {
    'TDs, FGs & Turnovers  ( lineup - 7 players )': [
      {
        action: 'Touchdown',
        result: '+6 Pts',
      },

      {
        action: 'Field goal',
        result: '+3 Pts',
      },

      {
        action: 'Turnover',
        result: '+1 Pt',
      },
    ],

    'Yards for both teams ( lineup - 6 players )': [
      {
        action: 'Passing yard',
        result: '+1 Pt',
      },
      {
        action: 'Rushing yard',
        result: '+1 Pt',
      },
    ],

    'TDs ( lineup - 7 players )': [
      {
        action: 'Touchdown',
        result: '+1 Pt',
      },
    ],

    'Completions for both QBs ( lineup - 2 players )': [
      {
        action: 'Completion for each QB',
        result: '+1 Pt',
      },
    ],
    'Rushing yards for both teams ( lineup - 4 players )': [
      {
        action: ' Rushing yard for each team',
        result: '+1 Pt',
      },
    ],

    'Receiving yards for both teams ( lineup 6 players )': [
      {
        action: 'Receiving yard for each team',
        result: '+1 Pt',
      },
    ],
  },

  NBA: {
    'Points & Rebounds ( lineup - 7 players )': [
      {
        action: 'Point',
        result: '+1 Pt',
      },
      {
        action: 'Rebound',
        result: '+1 Pt',
      },
    ],
  },

  NHL: {
    'Goals, Shots on Goal & Saves ( lineup - 9 players )': [
      {
        action: 'Goal',
        result: '+1 Pt',
      },
      {
        action: 'Shot on goal',
        result: '+1 Pt',
      },
      {
        action: 'Save',
        result: '+1 Pt',
      },
    ],
    'Shots on Goal ( lineup - 9 players )': [
      {
        action: 'Shot on goal',
        result: '+1 Pt',
      },
    ],

    'Assists ( lineup - 9 players )': [
      {
        action: 'Assist',
        result: '+1 Pt',
      },
    ],

    'Saves ( lineup - 2 players )': [
      {
        action: 'Save',
        result: '+1 Pt',
      },
    ],

    'Goals ( lineup - 9 players )': [
      {
        action: 'Goal',
        result: '+1 Pt',
      },
    ],
  },

  MLB: {
    'RBIs & Hits ( lineup - 12 players )': [
      {
        action: 'Run Batted In',
        result: '+1 Pt',
      },
      {
        action: 'Hit',
        result: '+1 Pt',
      },
    ],

    'Home runs ( lineup - 8 players )': [
      {
        action: 'Home Run',
        result: '+1 Pt',
      },
    ],
    'Hits ( lineup - 8 players )': [
      {
        action: 'Hit',
        result: '+1 Pt',
      },
    ],

    'Strikeouts ( lineup - 7 players )': [
      {
        action: 'Strikeout',
        result: '+1 Pt',
      },
    ],
  },
};
