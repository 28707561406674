import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 0 1em;
  //background-color: #212529;
  background-color: #29282E;
  display:flex;
  flex-direction: column;
  color: #fff;
  font-size: 0.8rem;
`

const TableLine = styled.div`
  //border-top: 1px solid #29282E;
  border-top: 1px solid rgb(123, 123, 123);
  //color:  rgb(123, 123, 123);
  display: flex;
  padding: 0.5em 1em;
  justify-content: space-between;
  &:nth-child(1){
    border: none;
  }
`;

const ScoringPointsTable = ({data}) => (
  <Wrapper>
    {
      data.map(item => (
        <TableLine key={item.action}>
          <span>{item.action}</span>
          <span>{item.result}</span>
        </TableLine>
      ))
    }
  </Wrapper>
)

export default ScoringPointsTable
